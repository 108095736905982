<template>
    <div class="card-producto m-2 p-1" :class="{'border-general':info.promocion && info.data_promocion.borde}" @click="$emit('accion',info)">
        <div class="row mx-0">
            <div class="col-xl-auto col-lg-auto col-md-12 col-sm-12 col-12 px-0 text-center">
                <img class="br-10" height="90" width="90" :src="info.producto.imagen" alt="" />
                <p v-show="pedido.impuestos === 2 && info.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-10 text-center">IVA incluido</p>
                <p v-show="pedido.impuestos === 1 && info.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-10 text-center"> + IVA</p>
            </div>
            <div class="col-xl col-lg col-md-12 col-sm-12 col-12 px-2 text-general">
                <div v-if="info.desconocido === 0" class="">
                    <p class="nombre lh-18 f-500">{{ info.producto.nombre }}</p>
                    <p class="f-13 text-general2">{{ info.producto.presentacion }}</p>
                    <div class="row mx-0 align-items-center text-general pb-1 justify-content-between">
                        <span class="f-600">{{ convertMoneyTendero(valor_producto, pedido.idm_moneda) }}</span>
                        <p>
                            <b>{{ agregarSeparadoresNumero(info.cantidad,decimalFraccion) }}</b>
                            {{ info.producto.unidad }}
                        </p>
                    </div>
                    <span v-if="info.promocion" class="bg-general br-10 text-white px-2 f-13">{{ info.data_promocion.texto }}</span>
                </div>
                <div v-else class="">
                    <p class="nombre lh-18 f-600">Producto Desconocido</p>
                    <p>{{ info.producto.nombre }}</p>
                </div>
            </div>
            <div class="d-flex flex-column">
                <i v-show="info.estado === 12" class="icon-account-plus f-22 my-auto cr-pointer text-dark" />
                <i v-show="info.estado === 21" class="icon-swap-horizontal-bold f-22 my-auto cr-pointer text-gr-general" />
                <i v-show="info.estado === 22" class="icon-plus f-22 my-auto cr-pointer text-gr-red" />
                <i v-show="info.estado === 23" class="icon-minus f-22 my-auto cr-pointer text-gr-red" />
                <i v-show="info.estado === 4" class="icon-down-circle f-22 my-auto cr-pointer text-gr-general" />
                <i v-show="info.estado === 51 || info.estado === 52" class="icon-trash-empty f-22 my-auto cr-pointer text-dark" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {
                    nombre: '',
                    presentacion:  '600',
                    precio:0
                }
            }
        }
    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            colores: [ '', 'bg-gr-general', 'bg-gr-red', 'bg-gr-purple', 'bg-gr-pink' ],
            text: [ '', 'text-gr-general', 'text-gr-red', 'text-gr-purple', 'text-gr-pink' ],
            border: [ '', 'border-blue', 'border-red', 'border-purple', 'border-pink' ],

        }
    },
    computed:{
        ...mapGetters({
            pedido: 'pedidos/pedidos_historial/pedido',
        }),
        decimalFraccion(){
            if(this.info.producto.cantidad_tipo === 1) return 0
            else return 2
        },
        impuesto(){
            const i = this.info.porcentaje_impuesto
            return i ? i : 0
        },
        valor_producto(){
            if(this.pedido.impuestos === 0){
                return this.info.total_final
            }
            if(this.pedido.impuestos === 1){
                return this.info.total_final
            }
            if(this.pedido.impuestos === 2 || this.pedido.impuestos === 3){
                return this.info.total_final * (1 + this.impuesto / 100)
            }
            return 0
        }
    },
    methods: {
        handleCommand(e){
            this.info.icono = e
            this.$emit('icono',{id:this.info.id, icon:e})
        },
        fun_icono(icon){
            switch (icon){
            case null:
                return 'icon-sticker-emoji'
            case 1:
                return 'icon-ok-circled-outline text-success'
            case 2:
                return 'icon-cancel-circled-outline text-danger'
            case 3:
                return 'icon-search text-primary'
            case 4:
                return 'icon-currency-usd-circle text-aqua'
            case 5:
                return 'icon-attention-outline text-gr-red'
            default:
                return 'icon-sticker-emoji'
            }
        }

    }
}
</script>

<style lang="css" scoped>
.card-producto{
    width: 400px;
    border-radius: 16px;
}
.nombre{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
.stock_bajo{
    background: red;
    color: #fff !important;
    border-radius: 10px;
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}
</style>
